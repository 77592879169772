<template>
	<div>
		<TopNav></TopNav>
		<Header></Header>
	
		<div class="Wrap">
			
			<div class="About">
				
				<div class="AboutNavs">
					<li :class="CurrentKey == 'us' ? 'On':''" @click="$Jump('/about/us')"><i class="el-icon-price-tag"></i>关于我们</li>
					<li :class="CurrentKey == 'contract' ? 'On':''" @click="$Jump('/about/contract')"><i class="el-icon-phone-outline"></i>联系我们</li>
					<li :class="CurrentKey == 'argument' ? 'On':''" @click="$Jump('/about/argument')"><i class="el-icon-attract"></i>用户协议</li>
					
				</div>
				
				<div class="Content">
					<h1>{{About.Title}}</h1>
					<div class="Detail" v-html="About.Detail">
						
					</div>
				</div>
				
			</div>
			
		</div>
			
		<Footer></Footer>
		
	</div>
	
</template>

<script>
	import {  Checkbox,Radio,Select,Option,Upload } from 'element-ui';
	export default {
	  name: 'AboutIndex',
	  props: {
	    msg: String
	  },
	  data() {
	      return {
			  CurrentKey:'',
			  About:{
				  Id:'',
				  Title:'',
				  Detail:''
			  }
	      }
	    },
	  components: {
		  "el-radio":Radio,
		  "el-select":Select,
		  "el-option":Option,
		  "el-upload":Upload,
		  "el-checkbox":Checkbox
	  },
	  computed:{
		  routeKey(){
			  return this.$route.params.Key
		  }
	  },
	  watch: {
		CurrentKey(val) {
		},
		routeKey(val) {
		  this.ParseNewsId(val)
		},
	  },
	  created() {
		  this.ParseNewsId(this.$route.params.Key)
	  },
	  methods:{
		  ParseNewsId(_key){
			  let ablutList = [
					{Key:'us',Id:'01a5b86e-e54c-4325-b963-a2406acc1892'},
					{Key:'contract',Id:'533f69e0-b619-4c17-816a-5f124f9a8f2c'},
					{Key:'argument',Id:'5d2d2bc2-6bdf-44da-8cae-3e207ef1cfa7'},
			  ]
			  
			  this.CurrentKey = _key
			  
			  let about = ablutList.find(t => t.Key == _key)
			  
			  if(this.CurrentKey == undefined){
				  this.$message('异常的入口')
				  return
			  }
			  this.GetAboutInfo(about.Id)
		  },
		  GetAboutInfo(_newsid){
			  if(_newsid == undefined){
				  return
			  }
			  let data = {
					Service:'News',
					Class: 'News',
					Action: 'Get',
					Id:_newsid
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((response) => {
			  				
					if(response.ErrorId != 0){
						this.$message({ message: response.Msg, type: 'error'});
						return
					}
			  		this.About = response.Data
			  })
		  }
	  }
	}
</script>

<style scoped>
.About{
	display: flex;
	margin-top: 20px;
	margin-bottom: 20px;
}
.About .AboutNavs{
	background-color: #FFFFFF;
	border:2px solid rgba(0,0,0,0.1);
	padding: 20px;
	margin-right: 20px;
}
.About .AboutNavs li{
	padding: 10px 30px;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	cursor: pointer;
	font-size: 1.2rem;
}
.About .AboutNavs li:hover{
	color: rgba(23,134,65,1);
}
.About .AboutNavs li i{
	margin-right: 10px;
}
.About .AboutNavs li.On{
	color: rgba(23,134,65,1);
}
.About .Content{
	flex: 1;
}
.About .Content .Detail{
	max-width: 800px;
}
</style>
